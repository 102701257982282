/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { blog, graphWrapper, person, blogPosting, organization } from "schema-comsat"
import { convertBreadcrumb } from "./schema-translate"

function SchemaBlog({ breadcrumbs, title, pathname, postList }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            canonicalUrl
            author
          }
        }
      }
    `
  )

  const rootUrl = site.siteMetadata.canonicalUrl

  const schema = graphWrapper(
    [
      blog({
        id: rootUrl + pathname,
        author: person({name: site.siteMetadata.author, url: rootUrl}),
        mainEntityOfPage: rootUrl + pathname,
        blogPost: postList.map(node => {
          return blogPosting({id: rootUrl + node.id, mainEntityOfPage: rootUrl + node.id})
        }),
        name: title,
        publisher: organization({name: site.siteMetadata.author, url: rootUrl}),
      }),
      convertBreadcrumb(rootUrl, breadcrumbs)
    ]
  );

  return (
    <Helmet>
      <meta name="twitter:card" content="summary" />
      <meta property="og:type" content="article" />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

SchemaBlog.propTypes = {
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  postList: PropTypes.any,
}

export default SchemaBlog
